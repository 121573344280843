var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banners-page" },
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("modals.deleteBanner"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.removeBanner,
          },
          on: {
            cancel: _vm.onCloseModal,
            accept: function () {
              return _vm.deleteBanner(_vm.id)
            },
            close: _vm.onCloseModal,
            "update:active": function ($event) {
              _vm.removeBanner = $event
            },
          },
        },
        [
          _vm.selectedBanner
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.selectedBanner.text[_vm.$i18n.locale])),
                  ]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("modals.deleteBannerQuestion")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("in-app-messaging-carousel-settings-sidebar", {
        attrs: { isSidebarActive: _vm.isOpenedCarouselSettingsSidebar },
      }),
      _c("in-app-messaging-sidebar", {
        attrs: {
          isSidebarActive: _vm.isOpenedSidebar,
          id: _vm.id,
          baseSubscriptionPlans: _vm.baseSubscriptionPlans,
        },
      }),
      _c(
        "div",
        { staticClass: "flex items-center mb-base" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-4",
              attrs: { color: "primary", type: "filled", icon: "add" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return function () {
                    return _vm.$serverBus.$emit("create-banner", null)
                  }.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("in-app-messaging.addBanner")) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "vx-tooltip",
            {
              attrs: {
                text: _vm.$t("in-app-messaging.carouselSettings"),
                position: "top",
                width: "38",
                height: "38",
              },
            },
            [
              _c("vs-button", {
                attrs: {
                  type: "border",
                  size: "medium",
                  color: "#3B86F7",
                  "icon-pack": "feather",
                  icon: "icon-settings",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.editInAppMessagingCarouselSettings.apply(
                      null,
                      arguments
                    )
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "banners-page__wrapper" }, [
        _c(
          "div",
          { staticClass: "banners-page__wrapper-holder" },
          [
            _c(
              "draggable",
              {
                attrs: { "ghost-class": "ghost", disabled: _vm.isMobile },
                on: {
                  start: function ($event) {
                    _vm.dragging = true
                  },
                  end: _vm.handleEnd,
                },
                model: {
                  value: _vm.sorted_banners,
                  callback: function ($$v) {
                    _vm.sorted_banners = $$v
                  },
                  expression: "sorted_banners",
                },
              },
              _vm._l(_vm.sorted_banners, function (banner) {
                return _c(
                  "div",
                  {
                    key: banner.id,
                    staticClass: "banners-page__wrapper-item flex items-center",
                  },
                  [
                    _c("in-app-messaging-banner", {
                      attrs: { banner: banner },
                    }),
                    _c(
                      "div",
                      { staticClass: "flex items-center ml-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row justify-center items-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleBannerIsEnabledState(
                                      banner
                                    )
                                  },
                                },
                              },
                              [
                                _c("toggle-switch-icon", {
                                  attrs: {
                                    enabled: banner.isEnabled,
                                    width: 40,
                                    height: 40,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "ml-1 mr-2",
                            attrs: {
                              text: _vm.$t("vue.edit"),
                              position: "top",
                            },
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                color: "primary",
                                type: "border",
                                size: "medium",
                                "icon-pack": "feather",
                                icon: "icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return function () {
                                    return _vm.$serverBus.$emit(
                                      "edit-banner",
                                      banner.id
                                    )
                                  }.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              text: _vm.$t("vue.delete"),
                              position: "top",
                            },
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                color: "danger",
                                type: "border",
                                size: "medium",
                                "icon-pack": "feather",
                                icon: "icon-trash",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return function () {
                                    return _vm.onDeleteBanner(banner)
                                  }.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }