var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass:
        "in-app-messaging-carousel-settings-sidebar items-no-padding",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isCarouselSettingsSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isCarouselSettingsSidebarActiveLocal = $$v
        },
        expression: "isCarouselSettingsSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("in-app-messaging.carouselSettings"))),
          ]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "primary",
                  size: "large",
                  type: "flat",
                  icon: "save",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.saveSettings.apply(null, arguments)
                  },
                },
              }),
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "dark",
                  size: "large",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.closeSettings.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _vm.inAppMessagingCarouselSettings
        ? _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              tag: "component",
              staticClass: "scroll-area--data-list-add-new",
              attrs: { settings: _vm.settings },
            },
            [
              _c("vx-card", [
                _c(
                  "div",
                  { staticClass: "flex flex-col items-start" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center relative mb-2" },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(_vm.$t("in-app-messaging.autoplayDelay"))
                          ),
                        ]),
                        _c(
                          "vx-tooltip",
                          {
                            staticStyle: { width: "17px" },
                            attrs: {
                              text: _vm.$t(
                                "in-app-messaging.autoplayDelayInfo"
                              ),
                              position: "top",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "17",
                                height: "17",
                                src: _vm.infoImg,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "w-full mr-2",
                      attrs: {
                        type: "number",
                        name: "autoplay-delay",
                        "data-vv-as": _vm.$t("in-app-messaging.autoplayDelay"),
                        value:
                          _vm.inAppMessagingCarouselSettings.autoplay.delay,
                      },
                      on: { input: _vm.onInputAutoplayDelay },
                    }),
                    _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                      _vm._v(_vm._s(_vm.errors.first("autoplay-delay"))),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex flex-col mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center relative mb-2" },
                    [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("in-app-messaging.transitionEffect"))
                        ),
                      ]),
                      _c(
                        "vx-tooltip",
                        {
                          staticStyle: { width: "17px" },
                          attrs: {
                            text: _vm.$t(
                              "in-app-messaging.transitionEffectInfo"
                            ),
                            position: "top",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "17",
                              height: "17",
                              src: _vm.infoImg,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c(
                        "vs-select",
                        {
                          staticClass: "w-full",
                          attrs: { autocomplete: "", color: "grey" },
                          model: {
                            value: _vm.inAppMessagingCarouselSettings.effect,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inAppMessagingCarouselSettings,
                                "effect",
                                $$v
                              )
                            },
                            expression: "inAppMessagingCarouselSettings.effect",
                          },
                        },
                        _vm._l(_vm.effects, function (effect) {
                          return _c("vs-select-item", {
                            key: effect.id,
                            attrs: { value: effect.value, text: effect.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }