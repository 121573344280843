<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="in-app-messaging-sidebar items-no-padding"
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ $t('in-app-messaging.banner') }}</h4>

      <div style="display: flex; flex-direction: row">
        <vs-button radius color="primary" size="large" type="flat" icon="save" @click.stop="saveBanner" />

        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="closeBanner" />
      </div>
    </div>

    <vs-divider class="mb-0" />

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <in-app-messaging-banner :banner="banner" />
      <vx-card>
        <div>
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('in-app-messaging.bannerText') }}</span>
            <div class="flex items-start">
              <div class="flex flex-col w-1/2 mr-2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.EN') }}</span>
                </div>
                <vs-input
                  name="en-banner-text"
                  class="w-full mb-2"
                  :data-vv-as="`${$t('in-app-messaging.bannerText')}(${$t('in-app-messaging.EN')})`"
                  v-model="banner.text.en"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('en-banner-text') }}</span>
              </div>
              <div class="flex flex-col w-1/2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.DE') }}</span>
                </div>
                <vs-input
                  name="de-banner-text"
                  class="w-full mb-2"
                  :data-vv-as="`${$t('in-app-messaging.bannerText')}(${$t('in-app-messaging.DE')})`"
                  v-model="banner.text.de"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('de-banner-text') }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row flex-wrap justify-between w-full mt-4">
            <div class="flex flex-col flex-grow">
              <div class="mb-2">{{ $t('vue.backgroundColor') }}</div>
              <div>
                <input class="pa-color-picker" type="color" v-model="banner.background" name="callToActionButtonBackground" />
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <div class="mb-2">{{ $t('vue.fontColor') }}</div>
              <div>
                <input class="pa-color-picker" type="color" v-model="banner.color" name="callToActionButtonColor" />
              </div>
            </div>
          </div>
          <div class="my-4">
            <div class="flex flex-col">
              <div class="flex items-center relative mb-2">
                <span> {{ $t('in-app-messaging.chooseBannerBackgroundImage') }}</span>
              </div>
              <div class="flex-center">
                <div v-if="!banner.backgroundImage" class="flex flex-row">
                  <vs-button type="none" class="upload-photo mr-2 sm:mb-0 mb-2 upload p-0">
                    <div class="con-upload">
                      <div class="con-img-upload">
                        <div class="con-input-upload">
                          <input type="file" @change="detectFiles" @click="onFileInputClick" accept=".jpeg,.jpg,.gif,.png" />
                          <span class="text-input" style="color: #000">{{ $t('vue.selectPicture') }}</span>
                          <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                            <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </vs-button>
                </div>
                <div class="flex flex-row items-center" v-else>
                  <vs-button
                    type="border"
                    color="danger"
                    size="medium"
                    @click.prevent="removeBannerBackgroundImage"
                    icon-pack="material-icons"
                    icon="photo"
                    icon-after
                  >
                    {{ $t('vue.delete') }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center relative mb-2">
            <span>{{ $t('vue.targetGroup') }}</span>
          </div>
          <multiselect
            v-if="baseSubscriptionPlansFormatted && baseSubscriptionPlansFormatted.length"
            v-select-overflow
            v-model="banner.selectedAudience"
            :options="baseSubscriptionPlansFormatted"
            :multiple="true"
            group-values="plans"
            group-label="plansLabel"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('vue.pickSome')"
            :selectLabel="$t('vue.pressEnterToSelect')"
            :selectedLabel="$t('vue.selectedLabel')"
            :deselectLabel="$t('vue.pressEnterToRemove')"
            label="name"
            track-by="id"
            :custom-label="customLabel"
            name="pricing-plans"
            :data-vv-as="$t('pricing.pricingPlans')"
            v-validate="'required'"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} {{ values.length > 1 ? $t('in-app-messaging.pricingPlansSelected') : $t('in-app-messaging.pricingPlanSelected') }}</span
              >
            </template>
          </multiselect>
          <span class="flex text-danger text-sm mt-2">{{ errors.first('pricing-plans') }}</span>
        </div>

        <div class="flex flex-col mt-4">
          <p class="mb-2">{{ $t('in-app-messaging.startDate') }}:</p>
          <FlatPickr class="mt-1 w-full" :config="startDateConfig" v-model="dateStart" @on-change="onChangeStartDate" />
        </div>
        <div class="flex flex-col mt-4">
          <p class="mb-2">{{ $t('in-app-messaging.endDate') }}:</p>
          <FlatPickr class="mt-1 mb-2 w-full" :config="endDateConfig" v-model="dateEnd" @on-change="onChangeEndDate" />
        </div>

        <div class="flex flex-row justify-center items-center mt-4">
          <div @click="toggleCallToActionButton">
            <toggle-switch-icon :enabled="banner.isCallToActionButtonEnabled" :width="40" :height="40" />
          </div>
          <div class="flex item-center ml-2 mt-1">
            <div class="mr-2">{{ $t('in-app-messaging.provideCallToActionButton') }}</div>
            <vx-tooltip class="flex items-center" style="width: 17px" :text="$t('in-app-messaging.callToActionButtonInfo')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>
          </div>
        </div>
        <div v-show="banner.isCallToActionButtonEnabled">
          <div class="flex flex-col mb-4">
            <span class="mb-2">{{ $t('in-app-messaging.validPublicUrl') }}</span>

            <div class="flex items-start mb-4">
              <div class="flex flex-col w-1/2 mr-2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.EN') }}</span>
                </div>
                <vs-input
                  name="en-link"
                  class="w-full mb-2"
                  :data-vv-as="$t('in-app-messaging.validPublicUrl')"
                  v-model="banner.callToActionButtonLink.en"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('en-link') }}</span>
              </div>
              <div class="flex flex-col w-1/2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.DE') }}</span>
                </div>
                <vs-input
                  name="de-link"
                  class="w-full mb-2"
                  :data-vv-as="$t('in-app-messaging.validPublicUrl')"
                  v-model="banner.callToActionButtonLink.de"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('de-link') }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="mb-2">{{ $t('in-app-messaging.callToActionButtonTitle') }}</span>

            <div class="flex items-start mb-4">
              <div class="flex flex-col w-1/2 mr-2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.EN') }}</span>
                </div>
                <vs-input
                  name="en-button-text"
                  class="w-full mb-2"
                  :data-vv-as="`${$t('in-app-messaging.callToActionButtonTitle')}(${$t('in-app-messaging.EN')})`"
                  v-model="banner.callToActionButtonText.en"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('en-button-text') }}</span>
              </div>
              <div class="flex flex-col w-1/2">
                <div class="flex items-center relative mb-2">
                  <span>{{ $t('in-app-messaging.DE') }}</span>
                </div>
                <vs-input
                  name="de-button-text"
                  class="w-full mb-2"
                  :data-vv-as="`${$t('in-app-messaging.callToActionButtonTitle')}(${$t('in-app-messaging.DE')})`"
                  v-model="banner.callToActionButtonText.de"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{ errors.first('de-button-text') }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row flex-wrap justify-between w-full">
            <div class="flex flex-col flex-grow">
              <div class="mb-2">{{ $t('vue.backgroundColor') }}</div>
              <div>
                <input class="pa-color-picker" type="color" v-model="banner.callToActionButtonBackground" name="callToActionButtonBackground" />
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <div class="mb-2">{{ $t('vue.fontColor') }}</div>
              <div>
                <input class="pa-color-picker" type="color" v-model="banner.callToActionButtonColor" name="callToActionButtonColor" />
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </component>
  </vs-sidebar>
</template>

<script>
import 'firebase/storage'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import { mapGetters } from 'vuex'

import InAppMessagingBanner from '@/views/pages/in-app-messaging/InAppMessagingBanner'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

const getBannerDefaultValues = () => {
  return {
    text: {
      en: 'This text is just for example',
      de: 'Dieser Text ist nur ein Beispiel'
    },
    background: '#3b86f7',
    backgroundImage: null,
    color: '#FFFFFF',
    selectedAudience: [],
    dateStart: null,
    dateEnd: null,
    isEnabled: true,
    // start callToActon button
    isCallToActionButtonEnabled: false,
    callToActionButtonLink: {
      en: 'www.letsconnect.at',
      de: 'www.letsconnect.at'
    },
    callToActionButtonText: {
      en: 'Follow',
      de: 'Folgen'
    },
    callToActionButtonBackground: '#F0A941',
    callToActionButtonColor: '#FFFFFF'
    // end callToActionButton
  }
}

export default {
  components: {
    InAppMessagingBanner,
    VuePerfectScrollbar,
    ToggleSwitchIcon,
    CheckIcon,
    CloseIcon,
    Multiselect,
    FlatPickr
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    id: {
      type: String
    },
    baseSubscriptionPlans: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      startDateConfig: {
        dateFormat: 'Y-m-d',
        minDate: 'today'
      },
      endDateConfig: {
        dateFormat: 'Y-m-d',
        minDate: 'today'
      },

      dateFormatForPickers: 'YYYY-MM-DD',
      dateStart: null,
      dateEnd: null,

      progressUpload: 0,
      uploadTask: null,

      infoImg: require('@/assets/images/elements/info.svg'),
      banner: getBannerDefaultValues()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$serverBus.$emit('toggle-in-app-messaging-sidebar', val)
        }
      }
    },
    baseSubscriptionPlansFormatted() {
      if (this.baseSubscriptionPlans && this.baseSubscriptionPlans.length) {
        const mollieTrialPlan = {
          id: 'M_MOLLIE_TRIAL',
          ids: ['M_MOLLIE_TRIAL'],
          name: 'Mollie Trial'
        }

        const plansLabel = this.$t('pricing.pricingPlans')
        const formattedArr = [
          {
            plansLabel,
            plans: [mollieTrialPlan, ...this.baseSubscriptionPlans]
          }
        ]
        return formattedArr
      }
      return []
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    bannerStyles() {
      return {
        backgroundColor: this.banner.background || '#3b86f7',
        color: this.banner.color || '#FFFFFF',
        backgroundImage: `url(${this.banner.backgroundImage})` || '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    },
    callToActionButtonStyles() {
      return {
        backgroundColor: this.banner.callToActionButtonBackground || '#F0A941',
        color: this.banner.callToActionButtonColor || '#FFFFFF'
      }
    }
  },

  watch: {
    async id() {
      if (!this.id) {
        this.setBannerDefaultValues()
      } else {
        if (this.banner && !this.banner.id) {
          const bannerRef = await this.$db.collection('banners').doc(this.id).get()
          if (bannerRef && bannerRef.data()) {
            this.banner = {
              ...this.banner,
              ...bannerRef.data(),
              id: this.id
            }
          }
        }
        if (this.banner.callToActionButtonLink) {
          this.banner = {
            ...this.banner,
            callToActionButtonLink: {
              en:
                this.banner.callToActionButtonLink && this.banner.callToActionButtonLink.en
                  ? this.banner.callToActionButtonLink.en
                  : this.banner.callToActionButtonLink,
              de:
                this.banner.callToActionButtonLink && this.banner.callToActionButtonLink.de
                  ? this.banner.callToActionButtonLink.de
                  : this.banner.callToActionButtonLink
            }
          }
        }
        if (this.banner.dateStart) {
          this.dateStart = moment(this.banner.dateStart).format(this.dateFormatForPickers)
        }
        if (this.banner.dateEnd) {
          this.dateEnd = moment(this.banner.dateEnd).format(this.dateFormatForPickers)
        }
      }
    }
  },
  mounted() {
    this.$serverBus.$on('create-banner', () => {
      this.resetFields()
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('create-banner')
  },
  methods: {
    onChangeStartDate(selectedDate) {
      const dateStart = moment(selectedDate[0]).format()
      this.banner = {
        ...this.banner,
        dateStart
      }
    },
    onChangeEndDate(selectedDate) {
      const dateEnd = moment(selectedDate[0]).format()
      this.banner = {
        ...this.banner,
        dateEnd
      }
    },
    detectFiles(event) {
      const { files } = event.target
      this.uploadFile(files[0])
    },
    async uploadFile(file) {
      await this.$vs.loading()
      this.uploadTask = null
      if (this.banner && !this.banner.id && !this.id) {
        const bannerRef = await this.$db.collection('banners').add({
          ...this.banner,
          created: new Date(),
          createdBy: this.activeUserInfo.uid
        })
        this.banner = {
          ...this.banner,
          id: bannerRef.id
        }
        this.$serverBus.$emit('edit-banner', this.banner.id)
        this.$serverBus.$emit('in-app-messaging-carousel-updated')
      }
      const storageRef = this.$firebase.storage().ref(`images/banners/${this.banner.id}`)

      this.uploadTask = storageRef.put(file)

      this.uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.progressUpload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      )
      this.uploadTask
        .then(({ ref }) => {
          return ref.getDownloadURL()
        })
        .then(async (url) => {
          const bannerRef = await this.$db.collection('banners').doc(this.banner.id)
          await bannerRef.set({ backgroundImage: url }, { merge: true })
          this.banner = {
            ...this.banner,
            backgroundImage: url
          }
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('vue.photoSavedSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
          await this.$vs.loading.close()
        })
        .catch(async (err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          /* eslint-disable no-console */
          console.error(err.message)
          await this.$vs.loading.close()
        })
    },
    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    },
    async removeBannerBackgroundImage() {
      await this.$vs.loading()
      const storageService = this.$firebase.storage()

      try {
        await storageService.refFromURL(this.banner.backgroundImage).delete()
        const bannerRef = await this.$db.collection('banners').doc(this.banner.id)
        await bannerRef.set({ backgroundImage: null }, { merge: true })
        this.banner = {
          ...this.banner,
          backgroundImage: null
        }
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = this.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      await this.$vs.loading.close()
    },
    customLabel({ name, billing_type }) {
      return `${name || ''} ${billing_type || ''}`
    },
    toggleCallToActionButton() {
      this.banner.isCallToActionButtonEnabled = !this.banner.isCallToActionButtonEnabled
    },
    async saveBanner() {
      const result = await this.$validator.validate()

      if (!result) {
        return
      }

      await this.$vs.loading()

      const banner = { ...this.banner }

      try {
        if (!this.id) {
          banner.created = new Date()
          banner.createdBy = this.activeUserInfo.uid

          await this.$db.collection('banners').add(banner)
        } else {
          banner.modified = new Date()
          banner.modifiedBy = this.activeUserInfo.uid

          await this.$db.collection('banners').doc(this.id).set(banner, { merge: true })
        }
        this.$serverBus.$emit('in-app-messaging-carousel-updated')
        this.isSidebarActiveLocal = false
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        await this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        /* eslint-disable no-console */
        console.error(err.message)
        await this.$vs.loading.close()
      }
    },

    resetFields() {
      this.dateStart = null
      this.dateEnd = null
      this.banner = getBannerDefaultValues()
      this.errors.clear()
      this.$validator.reset()
    },

    closeBanner() {
      this.isSidebarActiveLocal = false
      this.setBannerDefaultValues()
    },

    setBannerDefaultValues() {
      this.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.in-app-messaging-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 650px !important;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
.pa-color-picker {
  width: 300px;
  min-width: 300px;
  max-width: 100%;
  height: 40px;
  cursor: pointer;
}
.con-input-upload {
  width: 125px;
  height: 100px;
  font-size: 0.6rem;
  margin: 0 !important;
}

.con-img-upload {
  padding: 0 !important;
}
.con-input-upload .text-input {
  font-weight: 600 !important;
}
</style>
