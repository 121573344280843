<template>
  <div class="banners-page">
    <vs-prompt
      :title="$t('modals.deleteBanner')"
      @cancel="onCloseModal"
      @accept="() => deleteBanner(id)"
      @close="onCloseModal"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="removeBanner"
    >
      <div class="con-exemple-prompt" v-if="selectedBanner">
        <p>
          <strong>{{ selectedBanner.text[$i18n.locale] }}</strong>
        </p>
        {{ $t('modals.deleteBannerQuestion') }}
      </div>
    </vs-prompt>
    <in-app-messaging-carousel-settings-sidebar :isSidebarActive="isOpenedCarouselSettingsSidebar" />
    <in-app-messaging-sidebar :isSidebarActive="isOpenedSidebar" :id="id" :baseSubscriptionPlans="baseSubscriptionPlans" />
    <div class="flex items-center mb-base">
      <vs-button class="mr-4" color="primary" type="filled" icon="add" @click.prevent="() => $serverBus.$emit('create-banner', null)">
        {{ $t('in-app-messaging.addBanner') }}
      </vs-button>
      <vx-tooltip :text="$t('in-app-messaging.carouselSettings')" position="top" width="38" height="38">
        <vs-button
          type="border"
          size="medium"
          color="#3B86F7"
          icon-pack="feather"
          icon="icon-settings"
          @click.stop="editInAppMessagingCarouselSettings"
        ></vs-button>
      </vx-tooltip>
    </div>
    <div class="banners-page__wrapper">
      <div class="banners-page__wrapper-holder">
        <draggable v-model="sorted_banners" ghost-class="ghost" @start="dragging = true" @end="handleEnd" :disabled="isMobile">
          <div class="banners-page__wrapper-item flex items-center" v-for="banner in sorted_banners" :key="banner.id">
            <in-app-messaging-banner :banner="banner" />
            <div class="flex items-center ml-2">
              <div class="flex flex-row justify-center items-center">
                <div @click="toggleBannerIsEnabledState(banner)">
                  <toggle-switch-icon :enabled="banner.isEnabled" :width="40" :height="40" />
                </div>
              </div>
              <vx-tooltip :text="$t('vue.edit')" position="top" class="ml-1 mr-2">
                <vs-button
                  color="primary"
                  type="border"
                  size="medium"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click.prevent="() => $serverBus.$emit('edit-banner', banner.id)"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip :text="$t('vue.delete')" position="top" class="ml-1">
                <vs-button
                  color="danger"
                  type="border"
                  size="medium"
                  icon-pack="feather"
                  icon="icon-trash"
                  @click.prevent="() => onDeleteBanner(banner)"
                ></vs-button>
              </vx-tooltip>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { paddle } from '@/mixins/paddle'
import { mapGetters } from 'vuex'

import draggable from 'vuedraggable'

import InAppMessagingCarouselSettingsSidebar from '@/views/pages/in-app-messaging/InAppMessagingCarouselSettingsSidebar'
import InAppMessagingSidebar from '@/views/pages/in-app-messaging/InAppMessagingSidebar'
import InAppMessagingBanner from '@/views/pages/in-app-messaging/InAppMessagingBanner'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

export default {
  name: 'InAppMessaging',
  mixins: [paddle],
  components: {
    draggable,
    InAppMessagingCarouselSettingsSidebar,
    InAppMessagingSidebar,
    InAppMessagingBanner,
    ToggleSwitchIcon
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      dragging: false,
      infoImg: require('@/assets/images/elements/info.svg'),
      isOpenedCarouselSettingsSidebar: false,
      isOpenedSidebar: false,
      id: null,
      removeBanner: false,
      selectedBanner: null,
      baseSubscriptionPlans: null
    }
  },
  computed: {
    ...mapGetters({
      banners: 'banners'
    }),
    isMobile() {
      return this.windowWidth <= 767
    },
    sorted_banners: {
      get() {
        return this.banners
      },
      set(val) {
        this.$store.commit('UPDATE_BANNERS', val)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.baseSubscriptionPlans = this.getBaseSubscriptionPlans()
    this.$serverBus.$on('toggle-in-app-messaging-carousel-settings-sidebar', (isSidebarActive) => {
      this.isOpenedCarouselSettingsSidebar = isSidebarActive
    })
    this.$serverBus.$on('toggle-in-app-messaging-sidebar', (isSidebarActive) => {
      this.isOpenedSidebar = isSidebarActive
    })
    this.$serverBus.$on('create-banner', this.createBanner)
    this.$serverBus.$on('edit-banner', this.editBanner)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.$serverBus.$off('toggle-in-app-messaging-carousel-settings-sidebar')
    this.$serverBus.$off('toggle-in-app-messaging-sidebar')
    this.$serverBus.$off('create-banner')
    this.$serverBus.$off('edit-banner')
  },
  methods: {
    onCloseModal() {
      this.removeBanner = false
      this.selectedBanner = null
      this.id = null
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    handleEnd() {
      this.dragging = false
      this.sorted_banners.forEach(async (el) => {
        await this.$db.collection('banners').doc(el.id).set({ position: el.position }, { merge: true })
      })
      this.$serverBus.$emit('in-app-messaging-carousel-updated')
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    async toggleBannerIsEnabledState(banner) {
      const isEnabled = banner.isEnabled
      await this.$db.collection('banners').doc(banner.id).set({ isEnabled: !isEnabled }, { merge: true })
      this.$serverBus.$emit('in-app-messaging-carousel-updated')
    },
    createBanner() {
      this.id = null
      this.isOpenedSidebar = true
    },
    editBanner(id) {
      this.id = null
      setTimeout(() => {
        this.id = id
        this.isOpenedSidebar = true
      })
    },
    onDeleteBanner(banner) {
      this.removeBanner = true
      this.selectedBanner = banner
      this.id = banner.id
    },
    async deleteBanner(id) {
      try {
        this.removeBanner = false
        await this.$vs.loading()
        const bannerRef = await this.$db.collection('banners').doc(id).get()
        if (bannerRef && bannerRef.data()) {
          const bannerData = bannerRef.data()
          const storageService = this.$firebase.storage()
          if (bannerData.backgroundImage) {
            await storageService.refFromURL(bannerData.backgroundImage).delete()
          }
          await this.$db.collection('banners').doc(id).delete()
        }
        this.$serverBus.$emit('in-app-messaging-carousel-updated')
        await this.$vs.loading.close()
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        await this.$vs.loading.close()
      }
    },
    editInAppMessagingCarouselSettings() {
      this.isOpenedCarouselSettingsSidebar = true
    }
  }
}
</script>
<style lang="scss">
.banners-page {
  &__wrapper {
    @media (max-width: 767px) {
      overflow: hidden;
      &-holder {
        overflow: auto hidden;
      }
    }
    &-item {
      padding: 10px 15px;
      border-radius: 0.5rem;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      transform: translateY(0);
      cursor: pointer;
      @media (max-width: 767px) {
        min-width: 1200px;
      }
      &:hover {
        transform: translateY(-4px);
      }
    }
    &-item + &-item {
      margin-top: 1.3rem;
    }
  }
}
</style>
