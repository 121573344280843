<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="in-app-messaging-carousel-settings-sidebar items-no-padding"
    v-model="isCarouselSettingsSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ $t('in-app-messaging.carouselSettings') }}</h4>

      <div style="display: flex; flex-direction: row">
        <vs-button radius color="primary" size="large" type="flat" icon="save" @click.stop="saveSettings" />

        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="closeSettings" />
      </div>
    </div>

    <vs-divider class="mb-0" />

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl" v-if="inAppMessagingCarouselSettings">
      <vx-card>
        <div class="flex flex-col items-start">
          <div class="flex items-center relative mb-2">
            <span class="mr-2">{{ $t('in-app-messaging.autoplayDelay') }}</span>
            <vx-tooltip style="width: 17px" :text="$t('in-app-messaging.autoplayDelayInfo')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>
          </div>
          <vs-input
            class="w-full mr-2"
            type="number"
            name="autoplay-delay"
            :data-vv-as="$t('in-app-messaging.autoplayDelay')"
            :value="inAppMessagingCarouselSettings.autoplay.delay"
            v-validate="'required'"
            @input="onInputAutoplayDelay"
          />
          <span class="text-danger text-sm mt-2">{{ errors.first('autoplay-delay') }}</span>
        </div>

        <div class="flex flex-col mt-4">
          <div class="flex items-center relative mb-2">
            <span class="mr-2">{{ $t('in-app-messaging.transitionEffect') }}</span>
            <vx-tooltip style="width: 17px" :text="$t('in-app-messaging.transitionEffectInfo')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>
          </div>
          <div class="flex items-center">
            <vs-select class="w-full" v-model="inAppMessagingCarouselSettings.effect" autocomplete color="grey">
              <vs-select-item :value="effect.value" :text="effect.label" v-for="effect in effects" :key="effect.id" />
            </vs-select>
          </div>
        </div>
        <!-- <div class="flex flex-col mt-4">
          <div class="flex items-center relative mb-2">
            <span class="mr-2">{{ $t('in-app-messaging.direction') }}</span>
            <vx-tooltip :text="$t('in-app-messaging.directionInfo')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
          <div class="flex items-center">
            <vs-select class="w-full" v-model="inAppMessagingCarouselSettings.direction" autocomplete color="grey">
              <vs-select-item :value="direction.value" :text="direction.label" v-for="direction in directions" :key="direction.id" />
            </vs-select>
          </div>
        </div> -->
      </vx-card>
    </component>
  </vs-sidebar>
</template>

<script>
export default {
  name: 'InAppMessagingCarouselSettingsSidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      effects: [
        {
          id: 0,
          value: 'slide',
          label: 'slide'
        },
        {
          id: 1,
          value: 'fade',
          label: 'fade'
        },
        {
          id: 2,
          value: 'flip',
          label: 'flip'
        },
        {
          id: 3,
          value: 'coverflow',
          label: 'coverflow'
        }
      ],
      directions: [
        {
          id: 0,
          value: 'horizontal',
          label: 'horizontal'
        },
        {
          id: 1,
          value: 'vertical',
          label: 'vertical'
        }
      ],
      inAppMessagingCarouselSettings: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    isCarouselSettingsSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$serverBus.$emit('toggle-in-app-messaging-carousel-settings-sidebar', val)
        }
      }
    }
  },
  mounted() {
    this.getInAppMessagingCarouselSettings()
  },
  methods: {
    async getInAppMessagingCarouselSettings() {
      await this.$vs.loading()
      try {
        const query = await this.$db.collection('settings').doc('in-app-messaging-carousel-settings').get()
        const data = query.data()
        this.inAppMessagingCarouselSettings = data
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.loading.close()
        this.$notify({
          title: this.$i18n.t('vue.error'),
          text: err.message,
          type: 'error',
          duration: 8800,
          speed: 100
        })
      }
    },
    onInputAutoplayDelay(delay) {
      this.inAppMessagingCarouselSettings = {
        ...this.inAppMessagingCarouselSettings,
        autoplay: {
          ...this.inAppMessagingCarouselSettings.autoplay,
          delay: +delay
        }
      }
    },
    async saveSettings() {
      try {
        const settingsRef = await this.$db.collection('settings').doc('in-app-messaging-carousel-settings')
        await settingsRef.set(this.inAppMessagingCarouselSettings, { merge: true })
        this.$serverBus.$emit('in-app-messaging-carousel-updated', this.inAppMessagingCarouselSettings)
        this.isCarouselSettingsSidebarActiveLocal = false
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.loading.close()
        this.$notify({
          title: this.$i18n.t('vue.error'),
          text: err.message,
          type: 'error',
          duration: 8800,
          speed: 100
        })
      }
    },
    closeSettings() {
      this.isCarouselSettingsSidebarActiveLocal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.in-app-messaging-carousel-settings-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 650px !important;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
