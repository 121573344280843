var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "in-app-messaging-sidebar items-no-padding",
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("in-app-messaging.banner")))]),
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "row" } },
            [
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "primary",
                  size: "large",
                  type: "flat",
                  icon: "save",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.saveBanner.apply(null, arguments)
                  },
                },
              }),
              _c("vs-button", {
                attrs: {
                  radius: "",
                  color: "dark",
                  size: "large",
                  type: "flat",
                  icon: "close",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.closeBanner.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "scroll-area--data-list-add-new",
          attrs: { settings: _vm.settings },
        },
        [
          _c("in-app-messaging-banner", { attrs: { banner: _vm.banner } }),
          _c("vx-card", [
            _c("div", [
              _c("div", { staticClass: "flex flex-col" }, [
                _c("span", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("in-app-messaging.bannerText"))),
                ]),
                _c("div", { staticClass: "flex items-start" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-1/2 mr-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center relative mb-2" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("in-app-messaging.EN"))),
                          ]),
                        ]
                      ),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full mb-2",
                        attrs: {
                          name: "en-banner-text",
                          "data-vv-as":
                            _vm.$t("in-app-messaging.bannerText") +
                            "(" +
                            _vm.$t("in-app-messaging.EN") +
                            ")",
                        },
                        model: {
                          value: _vm.banner.text.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.banner.text, "en", $$v)
                          },
                          expression: "banner.text.en",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("en-banner-text"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-1/2" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center relative mb-2" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("in-app-messaging.DE"))),
                          ]),
                        ]
                      ),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full mb-2",
                        attrs: {
                          name: "de-banner-text",
                          "data-vv-as":
                            _vm.$t("in-app-messaging.bannerText") +
                            "(" +
                            _vm.$t("in-app-messaging.DE") +
                            ")",
                        },
                        model: {
                          value: _vm.banner.text.de,
                          callback: function ($$v) {
                            _vm.$set(_vm.banner.text, "de", $$v)
                          },
                          expression: "banner.text.de",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("de-banner-text"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-row flex-wrap justify-between w-full mt-4",
                },
                [
                  _c("div", { staticClass: "flex flex-col flex-grow" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.backgroundColor"))),
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.banner.background,
                            expression: "banner.background",
                          },
                        ],
                        staticClass: "pa-color-picker",
                        attrs: {
                          type: "color",
                          name: "callToActionButtonBackground",
                        },
                        domProps: { value: _vm.banner.background },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.banner,
                              "background",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex flex-col flex-grow" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.fontColor"))),
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.banner.color,
                            expression: "banner.color",
                          },
                        ],
                        staticClass: "pa-color-picker",
                        attrs: {
                          type: "color",
                          name: "callToActionButtonColor",
                        },
                        domProps: { value: _vm.banner.color },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.banner, "color", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "my-4" }, [
                _c("div", { staticClass: "flex flex-col" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center relative mb-2" },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "in-app-messaging.chooseBannerBackgroundImage"
                              )
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "flex-center" }, [
                    !_vm.banner.backgroundImage
                      ? _c(
                          "div",
                          { staticClass: "flex flex-row" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "upload-photo mr-2 sm:mb-0 mb-2 upload p-0",
                                attrs: { type: "none" },
                              },
                              [
                                _c("div", { staticClass: "con-upload" }, [
                                  _c("div", { staticClass: "con-img-upload" }, [
                                    _c(
                                      "div",
                                      { staticClass: "con-input-upload" },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: ".jpeg,.jpg,.gif,.png",
                                          },
                                          on: {
                                            change: _vm.detectFiles,
                                            click: _vm.onFileInputClick,
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-input",
                                            staticStyle: { color: "#000" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("vue.selectPicture")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-upload-all vs-upload--button-upload",
                                            attrs: {
                                              disabled: "disabled",
                                              type: "button",
                                              title: "Upload",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons notranslate",
                                                attrs: {
                                                  translate: "translate",
                                                },
                                              },
                                              [_vm._v(" cloud_upload ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "flex flex-row items-center" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: {
                                  type: "border",
                                  color: "danger",
                                  size: "medium",
                                  "icon-pack": "material-icons",
                                  icon: "photo",
                                  "icon-after": "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeBannerBackgroundImage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("vue.delete")) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-col" },
              [
                _c("div", { staticClass: "flex items-center relative mb-2" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("vue.targetGroup")))]),
                ]),
                _vm.baseSubscriptionPlansFormatted &&
                _vm.baseSubscriptionPlansFormatted.length
                  ? _c("multiselect", {
                      directives: [
                        {
                          name: "select-overflow",
                          rawName: "v-select-overflow",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        options: _vm.baseSubscriptionPlansFormatted,
                        multiple: true,
                        "group-values": "plans",
                        "group-label": "plansLabel",
                        "group-select": true,
                        "close-on-select": false,
                        "clear-on-select": false,
                        "preserve-search": true,
                        placeholder: _vm.$t("vue.pickSome"),
                        selectLabel: _vm.$t("vue.pressEnterToSelect"),
                        selectedLabel: _vm.$t("vue.selectedLabel"),
                        deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                        label: "name",
                        "track-by": "id",
                        "custom-label": _vm.customLabel,
                        name: "pricing-plans",
                        "data-vv-as": _vm.$t("pricing.pricingPlans"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function (ref) {
                              var values = ref.values
                              var isOpen = ref.isOpen
                              return [
                                values.length && !isOpen
                                  ? _c(
                                      "span",
                                      { staticClass: "multiselect__single" },
                                      [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " " +
                                            _vm._s(
                                              values.length > 1
                                                ? _vm.$t(
                                                    "in-app-messaging.pricingPlansSelected"
                                                  )
                                                : _vm.$t(
                                                    "in-app-messaging.pricingPlanSelected"
                                                  )
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1504065000
                      ),
                      model: {
                        value: _vm.banner.selectedAudience,
                        callback: function ($$v) {
                          _vm.$set(_vm.banner, "selectedAudience", $$v)
                        },
                        expression: "banner.selectedAudience",
                      },
                    })
                  : _vm._e(),
                _c("span", { staticClass: "flex text-danger text-sm mt-2" }, [
                  _vm._v(_vm._s(_vm.errors.first("pricing-plans"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col mt-4" },
              [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("in-app-messaging.startDate")) + ":"),
                ]),
                _c("FlatPickr", {
                  staticClass: "mt-1 w-full",
                  attrs: { config: _vm.startDateConfig },
                  on: { "on-change": _vm.onChangeStartDate },
                  model: {
                    value: _vm.dateStart,
                    callback: function ($$v) {
                      _vm.dateStart = $$v
                    },
                    expression: "dateStart",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col mt-4" },
              [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("in-app-messaging.endDate")) + ":"),
                ]),
                _c("FlatPickr", {
                  staticClass: "mt-1 mb-2 w-full",
                  attrs: { config: _vm.endDateConfig },
                  on: { "on-change": _vm.onChangeEndDate },
                  model: {
                    value: _vm.dateEnd,
                    callback: function ($$v) {
                      _vm.dateEnd = $$v
                    },
                    expression: "dateEnd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-row justify-center items-center mt-4" },
              [
                _c(
                  "div",
                  { on: { click: _vm.toggleCallToActionButton } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.banner.isCallToActionButtonEnabled,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex item-center ml-2 mt-1" },
                  [
                    _c("div", { staticClass: "mr-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("in-app-messaging.provideCallToActionButton")
                        )
                      ),
                    ]),
                    _c(
                      "vx-tooltip",
                      {
                        staticClass: "flex items-center",
                        staticStyle: { width: "17px" },
                        attrs: {
                          text: _vm.$t(
                            "in-app-messaging.callToActionButtonInfo"
                          ),
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.banner.isCallToActionButtonEnabled,
                    expression: "banner.isCallToActionButtonEnabled",
                  },
                ],
              },
              [
                _c("div", { staticClass: "flex flex-col mb-4" }, [
                  _c("span", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("in-app-messaging.validPublicUrl"))),
                  ]),
                  _c("div", { staticClass: "flex items-start mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-1/2 mr-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center relative mb-2" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("in-app-messaging.EN"))),
                            ]),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mb-2",
                          attrs: {
                            name: "en-link",
                            "data-vv-as": _vm.$t(
                              "in-app-messaging.validPublicUrl"
                            ),
                          },
                          model: {
                            value: _vm.banner.callToActionButtonLink.en,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.banner.callToActionButtonLink,
                                "en",
                                $$v
                              )
                            },
                            expression: "banner.callToActionButtonLink.en",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("en-link"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-1/2" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center relative mb-2" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("in-app-messaging.DE"))),
                            ]),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mb-2",
                          attrs: {
                            name: "de-link",
                            "data-vv-as": _vm.$t(
                              "in-app-messaging.validPublicUrl"
                            ),
                          },
                          model: {
                            value: _vm.banner.callToActionButtonLink.de,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.banner.callToActionButtonLink,
                                "de",
                                $$v
                              )
                            },
                            expression: "banner.callToActionButtonLink.de",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("de-link"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flex flex-col" }, [
                  _c("span", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("in-app-messaging.callToActionButtonTitle"))
                    ),
                  ]),
                  _c("div", { staticClass: "flex items-start mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-1/2 mr-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center relative mb-2" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("in-app-messaging.EN"))),
                            ]),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mb-2",
                          attrs: {
                            name: "en-button-text",
                            "data-vv-as":
                              _vm.$t(
                                "in-app-messaging.callToActionButtonTitle"
                              ) +
                              "(" +
                              _vm.$t("in-app-messaging.EN") +
                              ")",
                          },
                          model: {
                            value: _vm.banner.callToActionButtonText.en,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.banner.callToActionButtonText,
                                "en",
                                $$v
                              )
                            },
                            expression: "banner.callToActionButtonText.en",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("en-button-text"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-col w-1/2" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center relative mb-2" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("in-app-messaging.DE"))),
                            ]),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mb-2",
                          attrs: {
                            name: "de-button-text",
                            "data-vv-as":
                              _vm.$t(
                                "in-app-messaging.callToActionButtonTitle"
                              ) +
                              "(" +
                              _vm.$t("in-app-messaging.DE") +
                              ")",
                          },
                          model: {
                            value: _vm.banner.callToActionButtonText.de,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.banner.callToActionButtonText,
                                "de",
                                $$v
                              )
                            },
                            expression: "banner.callToActionButtonText.de",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("de-button-text"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row flex-wrap justify-between w-full",
                  },
                  [
                    _c("div", { staticClass: "flex flex-col flex-grow" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("vue.backgroundColor"))),
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.banner.callToActionButtonBackground,
                              expression: "banner.callToActionButtonBackground",
                            },
                          ],
                          staticClass: "pa-color-picker",
                          attrs: {
                            type: "color",
                            name: "callToActionButtonBackground",
                          },
                          domProps: {
                            value: _vm.banner.callToActionButtonBackground,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.banner,
                                "callToActionButtonBackground",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex flex-col flex-grow" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("vue.fontColor"))),
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.banner.callToActionButtonColor,
                              expression: "banner.callToActionButtonColor",
                            },
                          ],
                          staticClass: "pa-color-picker",
                          attrs: {
                            type: "color",
                            name: "callToActionButtonColor",
                          },
                          domProps: {
                            value: _vm.banner.callToActionButtonColor,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.banner,
                                "callToActionButtonColor",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }